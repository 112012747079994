import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/EcoClub/2024/Roleplay/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/2024/Roleplay/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/2024/Roleplay/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/2024/Roleplay/4.jpg';

import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import RoboticSidebar2024 from '../RoboticSidebar2024';


const Robotics2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/clubs/robotics-club/Robotics/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/clubs/robotics-club/Robotics/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/clubs/robotics-club/Robotics/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/clubs/robotics-club/Robotics/4.webp`;
    
    

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        // {
        //     src: p5,
        //     source: p5,
        //     rows: 1,
        //     cols: 1,
        //   },
        //   {
        //     src: p6,
        //     source: p6,
        //     rows: 1,
        //     cols: 1,
        //   },

    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center' >
                                Robotics and Artificial Intelligence
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 9    Date: 24 September 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur organised a session on ‘Robotics and Artificial Intelligence’ on Tuesday,
                                    24 September 2004. The resource person Mr Mo Qureshi, Director of BD for Asia Pacific at VEX Robotics, conducted
                                    an insightful session that covered a wide range of topics, like the foundational concepts of robotics and AI, current
                                    technological advancements and practical applications across various industries such as healthcare and
                                    manufacturing. Students engaged in meaningful discussions about the benefits and challenges posed by these
                                    technologies, particularly in terms of ethical considerations and job displacement.
                                    <br></br>
                                    The interactive format of the session encouraged participants to ask questions and share their perspectives,
                                    fostering a dynamic learning environment. Mr Mo Qureshi emphasised the importance of understanding both the
                                    potential and limitations of Robotics and Artificial Intelligence, providing valuable insights into future trends and the
                                    role these technologies will play in society. Overall, the session was well-received, sparking interest in further
                                    exploration of the subjects discussed and their implications for the future.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "The future of AI is not about man versus machine, rather it is man with machine, working
                                together to achieve what neither could accomplish alone."

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <RoboticSidebar2024 />
                        </Box>
                        {/* <Box marginBottom={4}>
               <Sidebar />
             </Box> */}

                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default Robotics2024;